<template>
  <div id="page-user-management" class="main-content">
    <div
      class="container px-0 shadow border mt-0 mt-sm-4 rounded-lg overflow-hidden"
    >
      <div class="p-4 bg-secondary">
        <p class="text-light mb-0 lead font-weight-medium">USER MANAGEMENT</p>
      </div>
      <div class="user-table table-responsive">
        <table class="table table-bordered mb-0" style="min-width: 720px">
          <thead>
            <tr>
              <th class="table-active" style="width: 1%">#</th>
              <th class="table-active">Full Name</th>
              <th class="table-active">Email</th>
              <th class="table-active">Role</th>
              <th class="table-active text-center" style="width: 1%">
                Approved
              </th>
              <th class="table-active" style="width: 1%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in users"
              :ref="`user${user.id}`"
              :key="`user${user.id}`"
              :class="{
                'is-editing': editingUser && editingUser.id == user.id,
              }"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  :ref="`userInputName${user.id}`"
                  v-model="user.username"
                  :disabled="!editingUser || editingUser.id != user.id"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  :ref="`userInputEmail${user.id}`"
                  v-model="user.email"
                  :disabled="!editingUser || editingUser.id != user.id"
                />
              </td>
              <td>
                <select
                  class="custom-select"
                  :ref="`userInputRole${user.id}`"
                  v-model="user.role.id"
                  :disabled="!editingUser || editingUser.id != user.id"
                >
                  <option :value="null" disabled>Select a role</option>
                  <option
                    v-for="role in roles"
                    :key="`role${role.id}`"
                    :value="role.id"
                  >
                    {{ role.name }}
                  </option>
                </select>
              </td>
              <td class="text-center">
                <i
                  class="fas fa-check-circle text-success"
                  v-if="user.approved"
                ></i>
                <button
                  class="btn btn-primary btn-sm"
                  title="Approve this user"
                  @click="approveUser(user)"
                  v-else
                >
                  Approve
                </button>
              </td>
              <td class="text-center text-nowrap">
                <button
                  class="btn btn-link"
                  title="Cancel edit user"
                  @click="editUser()"
                  v-if="editingUser && editingUser.id == user.id"
                >
                  <i class="fas fa-times text-dark"></i>
                </button>
                <button
                  class="btn btn-link"
                  title="Edit this user"
                  @click="editUser(user)"
                  v-else
                >
                  <i class="fas fa-edit text-dark"></i>
                </button>

                <button
                  class="btn btn-link ml-2"
                  title="Save this user changes"
                  @click="saveUser(user)"
                  v-if="editingUser && editingUser.id == user.id"
                >
                  <i class="fas fa-save text-dark"></i>
                </button>
                <button
                  class="btn btn-link ml-2"
                  title="Delete this user"
                  @click="deleteUser(user)"
                  :disabled="user.id == currentUser.id"
                  v-else
                >
                  <i
                    class="fas fa-trash-alt"
                    :class="{ 'text-danger': user.id != currentUser.id }"
                  ></i>
                </button>
              </td>
            </tr>
            <tr v-if="users.length < 1">
              <td class="font-italic text-muted text-center" colspan="6">
                No user yet
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading-spinner v-if="isLoading" />
  </div>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "user-management",
  components: {
    "loading-spinner": LoadingSpinner,
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("user")),
      users: [],
      roles: [],
      isLoading: false,
      editingUser: null,
    };
  },
  methods: {
    saveUser(user) {
      API.put(`users/${user.id}`, {
        username: user.username,
        email: user.email,
        role: user.role.id,
      })
        .then((retVal) => {
          this.editingUser = null;

          Swal.fire({
            position: "top",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            icon: "success",
            html: "User info saved",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editUser(user) {
      if (user) {
        if (this.editingUser && this.editingUser.id != user.id) {
          this.resetUserEdit();
        }

        this.$nextTick(() => {
          this.editingUser = JSON.parse(JSON.stringify(user));
        });
      } else {
        this.resetUserEdit();

        this.$nextTick(() => {
          this.editingUser = null;
        });
      }
    },
    resetUserEdit() {
      let userIndex = this.users.findIndex((u) => u.id == this.editingUser.id);

      this.$set(this.users, userIndex, this.editingUser);
    },
    getUsers() {
      API.get("users")
        .then((retVal) => {
          this.users = retVal.data;

          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;

          console.error(err);
        });
    },
    getRoles() {
      this.isLoading = true;

      API.get("users-permissions/roles")
        .then((retVal) => {
          this.roles = retVal.data.roles.filter((r) => r.name != "Public");

          this.getUsers();
        })
        .catch((err) => {
          this.isLoading = false;

          console.error(err);
        });
    },
    approveUser(user) {
      Swal.fire({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes I'm sure",
        cancelButtonText: "Cancel",
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-primary mr-3",
          cancelButton: "btn btn-light border ml-3",
        },
        icon: "question",
        html:
          `<kbd>${user.username}</kbd>` +
          `<div class="mt-4">Are you sure you want to approve this user?</div>`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return API.put(`users/${user.id}`, { approved: true })
            .then((response) => {
              user.approved = true;

              Swal.fire({
                position: "top",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                icon: "success",
                html: "User approved",
              });

              return "";
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
      });
    },
    deleteUser(user) {
      Swal.fire({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes I'm sure",
        cancelButtonText: "No",
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-danger mr-3",
          cancelButton: "btn btn-light border ml-3",
        },
        icon: "",
        html:
          "<div class='text-center py-5'><i class='fas fa-trash-alt text-danger fa-3x'></i></div>" +
          `<kbd>${user.username}</kbd>` +
          `<div class="mt-4">Are you sure you want to delete this user?</div>`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return API.delete(`users/${user.id}`)
            .then((response) => {
              this.users = this.users.filter((u) => u.id != user.id);

              Swal.fire({
                position: "top",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                icon: "success",
                html: "User deleted",
              });

              return "";
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
      });
    },
  },
  mounted() {
    this.getRoles();
  },
};
</script>